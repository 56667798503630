<template>
  <div style="min-height: 100vh;background-color: #171726;padding-bottom: 20px;">
    <div class="taskBg">
      <div class="task_title_ padding-sm">
        <img class="task_back" src="img/backw.png" alt="" @click="closePage">
        <span>成长体系</span>
        <div class="task_rank" style="background-image: url(img/hua_rank.png);" @click="goRank"></div>
      </div>
      <div class="face_card" style="background-image: url('img/face_card_bg.png');">
        <div class="face_card_left">
          <div class="name_avatar">
            <img class="face_card_left_avatar" :src="userInfo.head_portrait" alt="">
            <span class="face_card_left_name">{{ userInfo.nickname }}</span>
          </div>
          <div class="my_level">
            <div style="display: flex;align-items: center;">
              <div class="level">
                <img :src="'img/level/flowerRank/' + userInfo.rank + '.png'" alt="">
              </div>
              <img class="my_tq" src="img/my_tq.png" alt="" @click="showTq">
            </div>
            <div class="progress-box" style="width:200px">
              <div class="my-progress" :style="'width:' + progressPercent * 200 + 'px'"></div>
            </div>
            <div style="color:#ffffff;margin-top: 8px;">
              <span>{{ userInfo.score }}/</span><span>{{ userInfo.next_score }}</span><span>（还需{{ userInfo.next_score -
                userInfo.score }}经验值升级）</span>
            </div>
          </div>
        </div>
        <div class="face_card_right">
          <div class="today_score" style="background-image: url('img/six_bg.png');">
            <div class="today_title">今日经验</div>
            <div class="today_score">+{{ userInfo.today_score }}</div>
          </div>
        </div>
      </div>
      <div class="flower-box">
        <div class="icon-box">
          <img class="icon-item" src="img/flower_icon.png" alt="">
          <div class="icon-title margin-top-17">{{ userInfo.flower }}</div>
          <div class="icon-title">约为(￥{{ userInfo.money }})</div>
        </div>
        <div class="icon-box" @click="toExchange">
          <img class="icon-item" src="img/exchange_icon.png" alt="">
          <div class="icon-title margin-top-31">道具兑换</div>
        </div>
        <div class="icon-box" @click="toWithdraw">
          <img class="icon-item" src="img/withdraw_icon.png" alt="">
          <div class="icon-title margin-top-31">现金提现</div>
        </div>
      </div>
      <div class="invite-box" style="background-image: url('img/invite_bg.png');">
        <img v-if="userInfo.remain_red_packet == 0" class="invite-box-img" src="img/receive_unfinish.png" alt=""
          @click="goInvite">
        <div v-if="userInfo.remain_red_packet > 0" class="invite-box-img_"
          style="background-image: url('img/receive_unclick.png');" alt="" @click="showRedBag">
          <div class="badge">{{userInfo.remain_red_packet}}</div>
        </div>
      </div>
      <div class="day-task-box" style="background-image: url('img/day_task_bg.png');">
        <div class="task-content">
          <div class="task-item" v-for="(item, index) in dayTaskList" :key="index">
            <div class="task-item-left">
              <div class="task-text">{{ item.task_name + '(' + item.finish_num + '/' + item.task_num + ')' }}</div>
              <div class="exp-flower">
                <div class="exp-flower-left">
                  <img class="exp-icon" src="img/exp_icon.png" alt="">
                  <span>{{ item.score + '经验' }}</span>
                </div>
                <div class="exp-flower-right">
                  <img class="hua-icon" src="img/hua_icon.png" alt="">
                  <span>{{ item.flower+'朵花' }}</span>
                </div>
                <!-- <div class="exp-flower-right">
                  <img class="hua-icon" src="img/card_icon.png" alt="">
                  <span>1游园卡</span>
                </div> -->
              </div>
            </div>
            <div class="task-item-right">
              <img v-if="item.status == 1" src="img/receive_unclick.png" alt="" @click="clickRed(item)">
              <img v-if="item.status == 2" src="img/receive_clicked.png" alt="">
              <img v-if="item.status == 0" src="img/gogogo.png" alt="" @click="goFinish(item)">
            </div>
          </div>
        </div>

      </div>
      <div class="week-task-box" style="background-image: url('img/week_task_bg.png');">
        <div class="task-content">
          <div class="task-item" v-for="(item, index) in weekTaskList" :key="index">
            <div class="task-item-left">
              <div class="task-text">{{ item.task_name + '(' + item.finish_num + '/' + item.task_num + ')' }}</div>
              <div class="exp-flower">
                <div class="exp-flower-left">
                  <img class="exp-icon" src="img/exp_icon.png" alt="">
                  <span>{{ item.score + '经验' }}</span>
                </div>
                <div class="exp-flower-right">
                  <img class="hua-icon" src="img/hua_icon.png" alt="">
                  <span>{{ item.flower+'朵花' }}</span>
                </div>
                <!-- <div class="exp-flower-right">
                  <img class="hua-icon" src="img/card_icon.png" alt="">
                  <span>1游园卡</span>
                </div> -->
              </div>
            </div>
            <div class="task-item-right">
              <img v-if="item.status == 1" src="img/receive_unclick.png" alt="" @click="clickRed(item)">
              <img v-if="item.status == 2" src="img/receive_clicked.png" alt="">
              <img v-if="item.status == 0" src="img/gogogo.png" alt="" @click="goFinish(item)">
            </div>
          </div>
        </div>
      </div>
      <!-- <div class="display_flex justify-content_flex-justify align-items_center margin-tb-xs padding-lr-sm">
        <div>
          <div class="hua_all display_flex align-items_center padding-lr-sm">
                <img src="img/hua.png" alt="">
                <span>{{red_balance}}</span>
            </div>
          <div v-if="is_display == 1" class="hua_hint display_flex align-items_center padding-lr-sm margin-top-sm">
            <img src="img/hint.png" alt="">
            <span>{{ text_money }}</span>
          </div>
        </div>
        <div v-if="is_display == 1" class="hua_draw" style="background-image: url(img/hua_draw.png);" @click="goWithDraw">
          <i v-if="red_withdraw == 1" style="background-image: url(img/xiao.png);"></i>
        </div>
      </div> -->
    </div>
    <div class="taskList">
      <!-- 邀请任务 -->
      <!-- <div class="taskBox display_flex justify-content_flex-justify align-items_center"
        style="background-image: url(img/invite-Bg.png); background-size:100% 100%;">
        <div>
          <div class="lcTask display_flex align-items_center">
            <p class="margin-right-xs">邀请任务</p>
            <img src="img/lchint.png" alt="">
          </div>
          <div class="taskText margin-top">邀请好友注册并完成真人有效认证</div>
          <div class="goinvite margin-top" style="background-image: url(img/goinvite.png);" @click="goInvite"></div>
        </div>
        <div class="task_level display_flex flex-direction_column align-items_center" @click="getInPop">
          <div class="gethua" :class="levelNum(level) == 4 ? 'gethua_height' : ''"
            :style="{ backgroundImage: 'url(' + 'img/level/invite/' + levelNum(level) + '.png' + ')' }">
            <i v-if="red_invite == 1" style="background-image: url(img/xiao.png);"></i>
            {{ level }}
          </div>
          <span>{{ level }}级</span>
        </div>

      </div> -->

      <!-- 新人专享 -->
      <!-- <div class="taskBox margin-top-sm display_flex justify-content_flex-justify align-items_flex-start" v-if="Object.keys(new_user_welfare_list).length > 0">
          <div class="newP">
            <p class="margin-bottom-xs">新人专享</p>
            <span>{{new_user_welfare_list.wel_name}}</span>
          </div>
          <template>
            <div v-if="new_user_welfare_list.is_receive == 0" class="gobtn" style="background-image: url(img/gobtn.png);" @click="goFinish(new_user_welfare_list.redis_key)"></div>
            <div v-if="new_user_welfare_list.is_receive == 1" class="gethua" style="background-image: url(img/gethua.png);" @click="clickNewbag"></div>
          </template>
        </div> -->

      <!-- 每日任务 -->
      <!-- <div class="taskBox margin-tb-sm" v-if="dayTaskList.length > 0">
        <div class="dayTask display_flex align-items_center">
          <p class="margin-right-xs">每日任务</p>
          <img src="img/dayhint.png" alt="">
        </div>
        <div class="display_flex justify-content_flex-justify align-items_flex-start margin-top"
          v-for="(item, index) in dayTaskList" :key="index">
          <div class="imgBox">
            <div class="taskText margin-bottom-xs">{{ item.wel_name }}<span>({{ item.finish_num }}/{{ item.num }})</span>
            </div>
            <img :src="'img/box' + item.wel_level + '.png'" alt="" @click="clickProp(item.wel_level)">
          </div>
          <template>
            <template v-if="item.status == 1">
              <template v-if="item.is_receive == 0">
                <div v-if="!fromRoom || (item.wel_id != 5 && item.wel_id != 11)" class="gobtn"
                  style="background-image: url(img/gobtn.png);" @click="goFinish(item.redis_key)"></div>
                <div class="task_hint" v-if="fromRoom && (item.wel_id == 5 || item.wel_id == 11)" ></div>
              </template>
              <div v-if="item.is_receive == 1" class="gethua" style="background-image: url(img/gethua.png);"
                @click="clickRed(item)"></div>

            </template>
            <div v-if="item.status == 2" class="hashua" style="background-image: url(img/hashua.png);"></div>
          </template>

        </div>
      </div> -->

      <!-- 本周任务 -->
      <!-- <div class="taskBox margin-tb-sm" v-if="week_welfare_list.length > 0">
        <div class="dayTask display_flex align-items_center">
          <p class="margin-right-xs">本周任务</p>
          <img src="img/weekhint.png" alt="">
        </div>
        <div class="display_flex justify-content_flex-justify align-items_flex-start margin-top"
          v-for="(item, index) in week_welfare_list" :key="index">
          <div class="imgBox">
            <div class="taskText margin-bottom-xs">{{ item.wel_name }}<span>({{ item.finish_num }}/{{ item.num }})</span>
            </div>
            <img :src="'img/box' + item.wel_level + '.png'" alt="" @click="clickProp(item.wel_level)">
          </div>
          <template>
            <template v-if="item.status == 1">
              <div v-if="item.is_receive == 0" class="gobtn" style="background-image: url(img/gobtn.png);"
                @click="goFinish(item.redis_key)"></div>
              <div v-if="item.is_receive == 1" class="gethua" style="background-image: url(img/gethua.png);"
                @click="clickRed(item)"></div>
            </template>
            <div v-if="item.status == 2" class="hashua" style="background-image: url(img/hashua.png);"></div>
          </template>
        </div>
      </div> -->

    </div>

    <!-- 宝箱说明 -->
    <!-- <van-popup v-model="showBox" style="background:none;">
      <div class="boxProp display_flex flex-direction_column align-items_center"
        :style="{ backgroundImage: 'url(' + 'img/prop' + boxNum + '.png' + ')' }">
        <div class="boxtit">最高可获得:</div>
        <div class="box_hua display_flex align-items_center margin-top">
          <img src="img/hua.png" alt="">
          <p>x{{ boxHua }}</p>
        </div>
        <div class="box_hint display_flex align-items_center margin-top">
          <span>集满一定数量的</span>
          <img src="img/hua.png" alt="">
          <span>可提现哦~</span>
        </div>
      </div>
    </van-popup> -->

    <!-- 弹窗红包 -->
    <van-popup v-model="showRed" closeable close-icon="close" :style="{ width: '100%', background: 'none' }"
      :close-on-click-overlay="false" @close="closeRedbag">
      <div class="red_title">
        <img src="img/red_bag_bg.png" alt="">
      </div>
      <div class="redList display_flex align-items_center flex_wrap padding-sm">

        <div class="flip-container" @click="clickOpen(index)" v-for="(item, index) in bagList" :key="index">
          <div class="flipper" :class="{ 'transRed': redOpen == true }">
            <div class="front">
              <img src="img/red_bag.png" alt="">
            </div>
            <div class="back">
              <div class="small_bag" style="background-image: url('img/redbag_open.png');" v-if="redCur != index">
                <div class="bag_hua">
                  <div style="display: flex;justify-content: center;">
                    <img src="img/hua_icon.png" alt="">
                  </div>
                  <p>{{ item.flower }}</p>
                </div>
              </div>
              <div class="big_bag" style="background-image: url('img/redCur.png');" v-if="redCur == index">
                <div class="bag_hua">
                  <div style="display: flex;justify-content: center;">
                    <img src="img/hua_icon.png" alt="">
                  </div>
                  <p>{{ item.flower }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>

      </div>
      <!-- <div class="closeBtn" v-if="redOpen" @click="closeRedbag">
        <img src="img/closeBtn.png" alt="">
      </div> -->

    </van-popup>

    <!-- 指定红包 -->
    <!-- <van-popup v-model="showAssion" closeable close-icon="close" :style="{ width: '100%', background: 'none' }"
      :close-on-click-overlay="false">
      <div class="red_title2">
        <img src="img/red_title2.png" alt="">
      </div>
      <div class="bigRed" style="background-image: url(img/bigRed.png);">
        <div class="display_flex align-items_center justify-content_flex-center" style="padding-top:50px;">
          <img src="img/hua.png" alt="">
          <span>x</span>
          <p>{{ assionRed }}</p>
        </div>
      </div>

      <div class="closeBtn" @click="closeBigRed">
        <img src="img/closeBtn.png" alt="">
      </div>

    </van-popup> -->

    <!-- 邀请等级 -->
    <!-- <van-popup v-model="showInvite" closeable :close-icon="'img/in_close.png'"
      :style="{ width: '100%', background: 'none' }" :close-on-click-overlay="false">
      <div class="pop_invite" style="background-image: url(img/InPopBg.png);">
        <div class="InTask display_flex align-items_center">
          <p class="margin-right-xs">邀请等级</p>
          <img src="img/Inthint.png" alt="">
        </div>
        <div class="pop_level display_flex flex-direction_column justify-content_flex-justify align-items_center">
          <div class="text-center">
            <div class="gethua" :class="levelNum(inviteDetails.rank) == 4 ? 'gethua_height' : ''"
              :style="{ backgroundImage: 'url(' + 'img/level/invite/' + levelNum(inviteDetails.rank) + '.png' + ')' }">
              {{ inviteDetails.rank }}</div>
            <span class="margin-top-sm">{{ inviteDetails.rank }}级</span>
          </div>
          <div>
            <div class="In_step">
              <p :style="{ width: (inviteDetails.ratio * 100) + '%' }"></p>
            </div>
            <div v-if="inviteDetails.is_can_get == 0" class="In_hint">距离升级还差<span>{{ inviteDetails.differ_exp }}</span>人
            </div>
          </div>
          <div class="pop_hashua display_flex align-items_center margin-top-sm">
            <p><span v-if="inviteDetails.is_can_get == 0">下一级</span>可获得</p>
            <img src="img/hua.png" alt="">
            <span class="hua_num">{{ inviteDetails.flowers }}</span>
          </div>
          <div class="goGethua" v-if="inviteDetails.is_can_get == 1" style="background-image: url(img/bigGethua.png);"
            @click="getHuaPop"></div>
        </div>

      </div>
    </van-popup> -->

    <!-- 邀请等级领取 -->
    <!-- <van-popup v-model="showHuaPop" :style="{ width: '100%', background: 'none', marginTop: '-40px' }"
      :close-on-click-overlay="false">
      <div class="pop_hua display_flex flex-direction_column align-items_center"
        style="background-image: url(img/HuaPopBg.png);">
        <p>恭喜获得</p>
        <div class="pophua_box display_flex align-items_center justify-content_flex-center">
          <img src="img/hua.png" alt="">
          <p><span class="margin-lr-sm">x</span>{{ inviteDetails.flowers }}</p>
        </div>
        <div class="pop_close" @click="closePopHua">
          <img src="img/pop_close.png" alt="">
        </div>
      </div>
    </van-popup> -->
    <!-- <van-popup v-model="showTQ">
      <div class="pop-content" style="background-image: url('img/level/flowerRank/pop_bg.png');">
        <img class="close-btn" src="img/level/flowerRank/close_btn.png" alt="" @click="showTQ = false">
        <div class="tq-box">
          <div class="tq-item" v-for="(item, index) in tQInfo" :key="index">
            <img class="tq_img" :src="item.img" alt="">
            <div>{{ item.name }}</div>
            <div>
              <img src="img/level/flowerRank/lock.png" alt="">
              <span>{{}}</span>
            </div>
          </div>
        </div>
      </div>

    </van-popup> -->
    <wechat-share @childFn="parentFn" />

  </div>
</template>

<script>
import "@/assets/css/base.css"
import "@/assets/css/index.css"
// import "@/assets/fonts/ShiShangZhongHeiJianTi.ttf"
// import { welFareList, getLuckyWelfare, getWelfare, newUserGetRedPacket, inviteLevelDetails, getFlowers } from "@/api/api";
import { levelInfo, taskList, myPrivilege, getTaskPrize, clickRedBag } from "@/api/levelUp";
import NavBar from '@/components/navBar.vue';
import WechatShare from '@/components/wechatShare.vue';
export default {
  name: 'task',
  components: {
    NavBar,
    WechatShare
  },
  data() {
    return {
      roomId: 1,
      userInfo: {},
      dayTaskList: [],
      weekTaskList: [],
      progressPercent: 0,
      showTQ: false,
      tQInfo: [],
      taskId: '',
      showBox: false,
      boxNum: '',
      showRed: false,
      redOpen: false,
      bagList: [{ flower: '0' }, { flower: '0' }, { flower: '0' }, { flower: '0' }, { flower: '0' }, { flower: '0' }],
      redCur: -1
    };
  },
  computed: {
    levelNum() {
      return function (level) {
        if (level >= 0 && level < 6) {
          return 1
        } else if (level > 5 && level < 11) {
          return 2
        } else if (level > 10 && level < 21) {
          return 3
        } else if (level > 20) {
          return 4
        }
      }

    },
    boxHua() {
      if (this.boxNum == 1) {
        return 50
      } else if (this.boxNum == 2) {
        return 100
      } else if (this.boxNum == 3) {
        return 200
      }
    },

  },
  created() {
    let access_token = this.$route.query.access_token
    localStorage.setItem('access_token', access_token)
    if (this.$route.query.hasOwnProperty('type') && this.$route.query.type == 'room') {
      this.fromRoom = true
    }
    // this.fetchData()
    this.getLevelInfo()
    this.getTaskList()
    this.getMyPrivilege()
  },
  methods: {
    getLevelInfo() {
      levelInfo().then(res => {
        console.log(res)
        this.userInfo = res.data
        this.progressPercent = this.userInfo.score / this.userInfo.next_score
      })
    },
    getTaskList() {
      taskList().then(res => {
        this.dayTaskList = res.data[1]
        this.weekTaskList = res.data[2]
      })
    },
    getMyPrivilege() {
      myPrivilege().then(res => {
        this.tQInfo = res.data
      })
    },
    showTq() {
      this.$toast('敬请期待~')
    },
    showRedBag() {
      this.showRed = true
    },
    closePage() {
      let params = '调用'
      this.$bridge.callhandler('closePage', params, (data) => {
        // 处理返回数据
      })
    },
    goFinish(d) {
      console.log(d)
      this.$bridge.callhandler(d.route, d.room_id, (data) => {
        // 处理返回数据
      })
    },
    //跳转到兑换
    toExchange(){
      this.$toast('敬请期待~')
    },
    //跳转到提现
    toWithdraw() {
      this.$router.push({ path: '/huaWithDraw?hua_num=' + this.userInfo.flower })
    },
    // 等级弹窗
    getInPop() {
      inviteLevelDetails().then(response => {
        this.inviteDetails = response.data
        this.showInvite = true
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })

    },
    // 点击任务领取按钮
    clickRed(item) {
      console.log(item)
      getTaskPrize({ task_id: item.id }).then(response => {
        this.$toast('领取成功~')
        this.getTaskList()
        this.getLevelInfo()
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    //
    clickOpen(e) {
      if (this.redOpen == false) {
        let id = e + 1
        clickRedBag({ id: id }).then(response => {
          this.bagList = response.data
          // this.bagList.splice(e, 0, response.data.win_red)
          // this.fetchData()
          this.getLevelInfo()
          this.redOpen = true
          this.redCur = e
        },
          error => {
            this.$toast.fail(error)
          }).catch((error) => {
            this.$toast.fail(error)
          })
      }
    },
    closeRedbag() {
      this.showRed = false
      this.redOpen = false
      this.redCur = -1
    },
    closeBigRed() {
      this.showAssion = false
    },
    parentFn(url) {
      this.img = url;
    },
    // 去邀请新用户
    goInvite() {
      var img = this.img
      let str = {
        url: img
      }
      let param = JSON.stringify(str)
      let _this = this
      this.$bridge.callhandler('doShareTask', param, (data) => {
        // 处理返回数据
      })
    },
    //新人红包
    clickNewbag() {
      newUserGetRedPacket().then(response => {
        this.fetchData()
        this.$toast.success(response.msg)
      },
        error => {
          this.$toast.fail(error)
        }).catch((error) => {
          this.$toast.fail(error)
        })
    },
    goWithDraw() {
      this.$router.push({ path: '/huaWithDraw' })
    },
    goRank() {
      this.$router.push({ path: '/taskRank' })
    }

  },
  watch: {
    '$route': "fetchData"
  }
};
</script>